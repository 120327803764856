import * as React from "react";
import Hero from "../components/Layout/Hero";
import PageBody from "../components/PageBody";
import Paragraph from "../components/Paragraph";
import Layout from "../components/Layout";
import { createSeoData } from "../utils/page";

export default function California() {
  const seo = createSeoData({
    title: "CCPA - Do Not Sell",
    description:
      "Lingua Fonica is compliant with the CCPA, to see your data rights as a Californian citizen click here.",
  });

  const hero = {
    heroTitleLine1: "California do not sell policy",
    heroContent: "Details you should know",
  };

  return (
    <Layout seo={seo} hero={hero}>
      <Hero
        heroTitleLine1="California do not sell policy"
        heroContent="Details you should know"
        heroImage={null}
      />
      <PageBody limitMaxWidth>
        <Paragraph>
          The California Consumer Privacy Act of 2018 (CCPA) gives consumers
          more control over the personal information that businesses collect
          about them and the CCPA regulations provide guidance on how to
          implement the law. This landmark law secures new privacy rights for
          California consumers, including: The right to know about the personal
          information a business collects about them and how it is used and
          shared; The right to delete personal information collected from them
          (with some exceptions); The right to opt-out of the sale of their
          personal information; and The right to non-discrimination for
          exercising their CCPA rights.
        </Paragraph>
      </PageBody>
    </Layout>
  );
}
